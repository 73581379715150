<template>
    <div class="dash-container">
        <h1>
        APP ACCESS
        </h1>
        
        <hr style="margin: 5px 0px;border: 1px solid #e3e3e3;">
        <div>
        <br>
        <table class="section-header">
            <tr>
                <td>
                    <h3 style="font-weight: bold;">Download InstaCrypt Desktop:</h3>
                </td>
            </tr>
        </table>
        <a href="https://github.com/3dfosi/ic-desktop/releases/download/v0.2.0/InstaCrypt-v0.2.0-x64-Win10-exeInstall.zip"><img style="padding: 20px;" width="80px" src="../../assets/docs/windows64icon.png"></a>
        <a href="https://github.com/3dfosi/ic-desktop/releases/download/v0.2.0/InstaCrypt-v0.2.0-x64-MacOS.dmg"><img style="padding: 20px;" width="80px" src="../../assets/docs/mac64icon.png"></a>
        <a href="https://github.com/3dfosi/ic-desktop/releases/download/v0.2.0/InstaCrypt-v0.2.0-x64-Linux.tar.bz2"><img style="padding: 20px;" width="80px" src="../../assets/docs/linux64icon.png"></a>
        <table class="section-header">
            <tr>
                <td>
                    <h3 style="font-weight: bold;">Generate A New Secret:</h3>
                </td>
            </tr>
        </table>
        <br>
        <div class="search-bar">
          <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
             <ValidationProvider rules="required|min:2" name="alias" v-slot="{ errors, valid }">
              <b-field label="Device Alias:" :type="{ 'is-danger': errors[0], 'is-success': valid }" :message="errors">
                <b-input type="text" maxlength="50" v-model="alias" placeholder="i.e. Macbook Air"></b-input> 
                <button class="button search-button is-primary is-outlined" @click="handleSubmit(genSecret)">
                    <span>Generate</span>
                </button>
              </b-field>
             </ValidationProvider>
          </ValidationObserver>
        </div>
        <span v-if='this.smsg != ""' class="results">
            <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <table class="secret-results">
                <tr>
                    <td>
                        <b-field>
                            <b-input id="sec-string" readonly="readonly" type="text" :value="smsg" expanded></b-input> 
                            <button id="copy" class="button search-button is-primary is-outlined" @click=handleSubmit(copySecret)>COPY</button>
                        </b-field>
                    </td>
                </tr>
                <tr>
                    <td class="secret-warning">
                        <p>Copy and paste the above access secret into the 3DF Locker app and save it in a password safe afterwards because you will no longer be able to retrieve this secret after this screen disappears and the only way around it is to remove this secret below under the "Existing Secrets" section and generate a new one. Do not share this secret with anyone else under any circumstances.</p>
                    </td>
                </tr>
            </table>
            </ValidationObserver>
        </span>
        <span v-if='gmsg' class="error-message">{{ this.gmsg }}</span>
        <span v-if='gmsg' class="error-message"><br><br></span>
        <br>
        <table class="section-header">
            <tr>
                <td>
                    <h3 style="font-weight: bold;">Existing Secrets:</h3>
                </td>
            </tr>
        </table>
        <span v-if='emsg' class="error-message emsg">{{ this.emsg }}</span>
        <b-table class="app-access" 
          striped 
          default-sort-direction="asc" 
          default-sort="name" pack="fa" 
          sort-icon="arrow-down" 
          sort-icon-size="is-small"
          paginated
          per-page="3"
          :data="data"
        >
          <template v-for="column in columns">
            <b-table-column :key="column.id" v-bind="column">
                <template
                    v-if="column.searchable && !column.numeric"
                    slot="searchable"
                    slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        style="padding-left: 0px;"
                        placeholder="Search..."
                        size="is-small" />
                </template>
                <template v-slot="props">
                    {{ props.row[column.field] }}
                    <div v-if="column.field === 'remove'">
                      <button class='button mini-button is-primary is-outlined is-danger' 
                        :id="props.row.id"
                        alt="Deny" 
                        v-on:click="removeSecret(props.row.id)"
                        :disabled="!props.row.exist" 
                      >
                        <span v-if="props.row.exist==true"><b-icon pack="fa" icon="remove"></b-icon></span>
                        <span v-if="props.row.exist==false">Removed</span>
                      </button>
                    </div>
                </template>
            </b-table-column>
          </template>
        </b-table>
        </div>
    </div>
</template>
<script>

import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
    components: {
      ValidationObserver,
      ValidationProvider
    },
    data() {
      return {
        smsg: "",
        gmsg: "",
        emsg: "",
        alias: "",
        disabled: false,
        data: [],
        columns: [
          {
              field: 'alias',
              label: 'Device Alias',
              width: 100,
              searchable: true,
              sortable: true,
          },
          {
              field: 'footprint',
              label: 'Access Secret Footprint',
              width: 100,
          },
          {
              field: 'remove',
              label: 'Remove',
              width: 40,
          }
        ]
      }
    },
    watch: {
    '$props':{
      handler: function (val, oldVal) { 
        console.log('watch', val);
        console.log('watch', oldVal);
        this.message="";
        this.getData();
      },
      deep: true
    }
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      requestAnimationFrame(() => {
        var api = process.env.VUE_APP_API;
        var query = this.q;
        console.log("API: " + api);
        console.log("Searching: " + query);
        this.loading = true;

        this.$store.dispatch('auth/get_app_access', query).then(
        (response) => {
            console.log(response);
            if (response.data.status == true) {
                console.log("if loop: ");
                console.log(response.data.data);
                this.data = response.data.data;
            } else {
              this.emsg = "You have no secrets..."
              console.log("You have no secrets");
              this.data = response.data.data;
            }
        },
        error => {
            this.loading = false;
            this.data = [];
            this.emsg = "You have no secrets...";
            console.log(error);
        });

      });
    },
    genSecret() {
        requestAnimationFrame(() => {
            this.smsg = "";
            this.gmsg = "";
            var api = process.env.VUE_APP_API;
            console.log("API: " + api);
            console.log("Generating Secret: " + this.alias);
            this.loading = true;

            this.$store.dispatch('auth/gen_secret', this.alias).then(
            (response) => {
                console.log(response);
                if (response.status == true) {
                    this.smsg = response.secret.secret;
                    this.getData();
                } else {
                    this.gmsg = response.message;
                    console.log(response.message);
                }
            },
            error => {
                this.gmsg = "Server Error...";
                console.log(error);
            });
            this.loading = false;
        });
    },
    removeSecret(sid) {
        this.emsg = "";
        requestAnimationFrame(() => {
        var api = process.env.VUE_APP_API;
        console.log("API: " + api);
        console.log("Removing: " + sid);
        this.loading = true;

        this.$store.dispatch('auth/remove_secret', sid).then(
        (response) => {
            console.log(response.status);
            if (response.status == true) {
               var index = this.data.map(e => e.id).indexOf(sid);
               console.log(index);
                this.data[index].exist = false;
            } else {
              this.emsg = "Server Error...";
              console.log("Server Error...");
            }
        },
        error => {
            this.emsg = "Server Error...";
            console.log(error);
        });
        this.loading = false;
      });

    },
    copySecret() {
        var copyText = document.getElementById("sec-string");

        copyText.select();
        copyText.setSelectionRange(0, 99999); /*For mobile devices*/

        document.execCommand("copy");

        var copyButton = document.getElementById("copy");
        copyButton.innerHTML = "COPIED";
        copyButton.focus();
    },
    refresh() {
        setTimeout(() => this.getData(), 1000);
    }
  }
}
</script>
<style lang='scss' scoped>
h1 {
    font-weight: bold;
}
.app-access {
    padding-top: 30px;
}
.mini-button {
    padding: 10px;
    margin-left: 5px;
    height: 15px;
}
.section-header {
    // color: #f5f5f5;
    background-color: #cccccc;
    width: 100%;
    padding: 20px; 
}

.section-header td {
    padding: 10px;
}

.results {
    margin-top: 0px;
    padding-top: 0px;
    background-color: #f5f5f5
}

.secret-results {
    border: 1px dashed #4d4d4d;
    width: 100%;
    margin-top: 0px;
    background-color: #f5f5f5;
}

.secret-results td {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
}

.secret-warning {
    width: 100%;
    color: red;
    vertical-align: text-top;
    font-size: 12px;
    padding-bottom: 20px;
}

.error-message {
    color: red;
}

.emsg {
    text-align: left;
}
</style>